.modalSaveConfig {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 55vw;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5.5vh 25px 5vh 25px;
  box-sizing: border-box;
  text-align: center;

  .close {
    position: absolute;
    z-index: 10;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #b57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
  .title {
    position: relative;
    z-index: 9;
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    color: #b57345;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    @media screen and (max-width: 670px) {
      font-size: 26px;
      line-height: 28px;
    }
  }
}
.logo {
  width: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}
.image {
  height: 300px;
  width: 100%;
  object-fit: contain;
  transform: scale(1.5);

  &__wrapper {
    width: 100%;
    z-index: -1;
  }
}

.table_scroll {
  height: auto;
  margin-right: 5px;
}
.table {
  &_info {
    display: flex;
    align-items: center;
    background: #5a6670;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 15px;

    &.additional {
      border-radius: 0;
    }
  }
  &_text {
    // word-break: keep-all;

    word-break: break-all;

    span {
      display: none;
      font-size: 14px;
    }
    .table_material {
      font-size: 12px;
    }
    &.name {
      display: block;
      font-size: 14px;

      span {
        display: block;
      }
    }
  }
  &_title {
    font-style: normal;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  &_item {
    display: flex;
    align-items: center;
    background: #e9e7db;
    padding: 10px 15px;
    border-bottom: 1px solid #5a6670;
    border-left: 1px solid #5a6670;
    border-right: 1px solid #5a6670;

    &.additional {
      border-style: solid;
      border-color: #5a6670;
      border-radius: 0px 0px 10px 10px;
    }
  }
}

.info {
  &__block {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.dimensions {
  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #101820;
    margin-bottom: 5px;
  }
  &_info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #101820;
  }
}

.buttons__block {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #101820;

  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.05em;
    color: #b57345;
    margin-bottom: 5px;
  }
}

.print {
  &__block {
    transition: all 0.2s;
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #b57345;
    background: transparent;
    border-radius: 50px;
    width: 100px;
    padding: 10px 5px;

    span {
      transition: all 0.2s;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #b57345;
    }
    svg {
      margin-right: 10px;
      margin-left: 10px;
      path {
        transition: all 0.2s;
        stroke: #b57345;
      }
    }
    &:hover {
      background: #b57345;

      span {
        color: #fff;
      }
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
}

.bodyWrap {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  overflow-x: hidden;
}

.addToCart {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b57345;
  border: 1px solid #b57345;
  border-radius: 50px;
  margin-left: 5px;
  padding: 11px 17px;
  svg {
    path {
      transition: all 0.2s;
    }
  }
  span {
    transition: all 0.2s;
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 0px;
  }

  &:hover {
    background: transparent;
    span {
      color: #b57345;
    }
    svg {
      path {
        stroke: #b57345;
      }
    }
  }
}

.mobile {
  display: none;
}

@media (max-width: 1400px) {
  .table__block {
    height: 250px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 1024px) {
  .modalSaveConfig {
    max-width: 90vw !important;
  }
  .table_text {
    span {
      display: block;
    }
  }
}
@media (max-width: 600px) {
  .addToCart,
  .print__block {
    padding: 5px 10px;

    span {
      font-size: 10px;
      margin-left: 5px;
    }
    img,
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .print__block {
    padding: 3px 10px;
  }
  .table_info.origin {
    .table_title {
      display: none;
    }
  }
  .table_item {
    padding-bottom: 40px;
    position: relative;
  }

  .mobile {
    display: block !important;
  }
  .sku {
    width: 100% !important;
    position: absolute;
    bottom: 10px;
  }
  .name {
    width: 70% !important;
  }
  .price {
    width: 45px !important;
  }
  .table_info.additional {
    display: none;

    .table_title {
      font-size: 12px;
    }
    .table_text {
      font-size: 12px;
    }
  }
  .table_item.additional {
    flex-direction: column;
    padding-top: 10px !important;

    .table_text {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;

      span {
        display: flex;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 12px;
        line-height: 130%;
        color: #101820;
        margin-right: 10px;
      }
    }
  }
  .image__wrapper {
    // height: 150px;
    // min-height: 150px;
    height: calc(var(--vh, 1vh) * 100 - 480px);
  }
  .title {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .logo {
    width: 80px;
  }
  .title {
    font-size: 20px;
    line-height: 18px;
  }
  .table_scroll {
    height: 250px;
  }
}

@media (max-width: 430px) {
  .image__wrapper {
    position: relative;
    top: -30px;
  }
  .table__block {
    position: relative;
    top: -30px;
    height: 200px;
    padding-top: 30px;
  }
  .buttons__block {
    margin-top: -30px;
  }
  .modalSaveConfig {
    padding-bottom: 10px;
  }
}
